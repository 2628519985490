// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

/* Sass: Contact form 7 Style */
    .wpcf7-validation-errors, .wpcf7-mail-sent-ng {
  background-color: #f2dede;
  border-color: #eed3d7 !important;
  text-align: center;
  color: #b94a48;
  clear: both;
  margin: 15px 0 0;
  overflow: hidden;
  padding: 8px 10px;
}

.wpcf7-not-valid-tip {
  color: #b94a48 !important;
}

    .wpcf7-mail-sent-ok {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #468847;
  clear: both;
  margin: 15px 0 0;
  overflow: hidden;
  padding: 10px;
}

    // .wpcf7-not-valid-tip { border: 0; color: #b94a48; font-size: 1em; left: 5px; padding: 0 2px; position: absolute; top: 7px; width: 80%; z-index: 100; }
    span.required {
  color: #b94a48;
}

// Additional Fixing

// Ajax Loader
#content img.ajax-loader {
  bottom: -25px;
  left: 49%;
  margin: 10px 0 0;
  position: absolute;
}

// IE Fixing
.ie8 .wpcf7 input {
  line-height: 35px;
}