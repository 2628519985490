.archive, .single-post, .page {
	.section-banner {
		@include degrade-violet-rose;
		margin-bottom: 35px;
		
		h1 {
			color: #fff;
			text-align: center;
			margin: 15px 0;
		}
	}
}


.archive {
	
	
	.header-page {
		//@include page-header;
		
		text-align: center;
		
		h1 {
			margin: 45px 0;
		}
	}

	.container-article {
		
		
		&:nth-child(even) {
			.row {
				background: $bg-gris-clair;
			}
		}
		
		.row {
			padding: 45px 0;
		}
	
				
			
			article {
				h2 {
					font-size: 30px;
					font-weight: 200;
					color: $violet;
					margin-top: 0;
					
					@media(max-width: $screen-sm) {
						text-align: center;
					}
					
					a {
						color: $text-color;
						
					}
		
				}
				
				.excerpt p {
					font-weight: 200;
					font-size: 14px;
					
					@media(max-width: $screen-sm) {
						text-align: center;
					}
				}
				
				.read-more {
					margin-bottom: 0;
					
					@media(max-width: $screen-sm) {
						text-align: center;
					}
				}
			}
	
	}


}


.single {
	
	.header-page {
		//@include page-header;
		
		text-align: center;
		
		h1,p {
			margin: 45px 0;
		}
	}
	
	article {
		h1 {
			//font-size: 30px;
			font-weight: 100;
			color: $violet;
			margin-top: 0;
	
			@media(max-width: $screen-sm) {
				text-align: center;
			}
	
		}
	}
}

.single, .archive {
	
	
	.container-date {
		//border: 1px solid #000;
		//border-radius: 50px;
		width: 100px;
		height: 100px;
		padding-top: 5px;
		
		@media(max-width: $screen-sm) {
			margin: 0 auto;
		}
		
		.jour {
			color: $violet;
			font-weight: 600;
			font-size: 45px;
			text-align: center;
			margin-bottom: 0;
			line-height: 45px;
		}
		
		.mois {
			color: #000;
			font-weight: 200;
			font-size: 22px;
			line-height: 22px;
			margin-bottom: 0;
			text-align: center;
			text-transform: uppercase;
		}
		
		.annee {
			color: #000;
			font-weight: 600;
			font-size: 16px;
			line-height: 16px;
			margin-bottom: 0;
			text-align: center;
		}
	}
}