.single-travailler_dans {
	
	.description-metier {
		
		padding: 35px 0;
		background-position: right bottom;
		background-repeat: no-repeat;
		
		@media(max-width: $screen-sm) {
			background: none !important;
		}
		
		h1 {
			font-size: 40px;
		}
		
		h2 {
			font-size: 20px;
			text-transform: uppercase;
		}
		
		.fiche-recap {
			@include degrade-rose-jaune;
			padding: 15px;
			
			color: #fff;
			
			p, ul li {
				color: #fff;
			}
			
			p:first-child {
				position: relative;
				padding-left: 40px;
				font-weight: 500;
				
				&:before {
					content: url('../images/puce-li.png');
					left: 15px;
					position: absolute;
					top: 0;
				}
			}
			
			.title {
				text-transform: uppercase;
			}
			
			ul {
				list-style: none;
				padding: 0;
				
				> li {
					margin-bottom: 5px;
				}
			}
		}
		
			
			h1,h2 {
				color: $violet;
			}
			
			h2 {
				padding-left: 30px;
				position: relative;
				
				&:before {
					content: url('../images/puce-li.png');
					left: 15px;
					position: absolute;
					top: 0;
				}
			}
			
	}

	.diplomes-metier {
		padding: 35px 0;
		@include degrade-violet-rose;
		
		.title {
			font-size: 30px;
			color: #fff;
			text-align: center;
		}
		
		.description {
			color: #fff;
			text-align: center;
			margin-bottom: 35px;
		}
		
		ul.list-diplomes {
			text-align: center;
			> li {
				display: inline-block;
				width: 20%;
				padding: 0 15px;
				
				> .wrapper {
					background: #fff;
					padding: 15px;
					
					.title-diplome {
						color: $violet;
						text-transform: uppercase;
						font-size: 18px;
						text-align: center;
					}
					
					.description-diplome {
						line-height: 22px;
					}
					
					.lien {
						text-align: center;
					}
				}
			}
		}
	}

	.section-temoignage {
		background: $violet;
		padding: 35px 0;
		position: relative;
		
		@media(min-width: $screen-md) {
			&:before {
				position: absolute;
				z-index: 1;
				content: url('../images/temoignage-left.png');
				left: 15px;
				top: -50px;
			}
	
			&:after {
				position: absolute;
				z-index: 1;
				content: url('../images/temoignage-right.png');
				right: 15px;
				top: -100px;
			}
		}
		
		.title p {

			color: #fff;
			font-size: 40px;
		}
		
		.bloc-texte {
			@include bloc-texte-blanc;
			p {
				text-align: center;
			}
		}
	}

}