.post-nav {
	ul.pager {
		> li {
			
			
			a {
				color: #fff;
				@include degrade-rose-jaune;
			}
			
		}
	}
}

article {
	.post-th {
		margin-bottom: 15px;
	}
}

.single-diplome {
	.vc_row {
		margin-left: auto;
		margin-right: auto;
	}
}