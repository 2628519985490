.single-diplome {
	
	h1 {
		color: $jaune;
		font-size: 40px;
	}
	
	.box-enseignement {
		h2 {
			color: $jaune;
			font-size: 30px;
		}
		
		.liste {
			ul {
				text-align: center;
			}
		}
	}
	
	.section-programme {
		padding-bottom: 35px;
		
		&.vc_row-has-fill {
			background-position: right center;
			background-size: auto 110%;
			background-repeat: no-repeat;
			
			@media(max-width: $screen-sm) {
				background-image: none !important;
			}
		}
		
		.box-shadow-rose {
			
			> .vc_column-inner > .wpb_wrapper  {
				border: #fbdfeb 1px solid;
				@include shadow-box-rose;
				background: #fff;
				padding: 15px;
				margin-bottom: 15px;
				
				ul {
					list-style: none;
					margin: 0;
					padding: 0;
					
					>li {
						margin-bottom: 10px;
					}
				}
			}
		}
	}
	
	.section-specificite {
		@include degrade-rose-jaune;
		
		h3 {
			color: $violet;
			font-size: 40px;
		}
				
		.bloc-texte {
			@include bloc-texte-blanc;
		}
	}
	
	.section-partenaires {
		padding-top: 35px;
		
		.title {
			p {
				color: $jaune;
				font-size: 40px;
			}
		}
	}
	
	.section-inscription {
		background: $jaune;
		padding-top: 35px;
		
		.title {
			p {
				color: $violet;
				font-size: 40px;
			}
		}
		
		.bloc-texte {
			@include bloc-texte-blanc;
		}
		
		.decale-top {
			img {
				margin-top: -100px;
			}
		}
	}
	

}

.section-passerelles {
	@include degrade-violet-rose;
	padding-top: 35px;
	padding-bottom: 35px;
	
	ul.list-diplomes {
		text-align: center;
		margin: 35px 0 0 0;
		padding: 0;
		font-size: 0;
		
		li {
			display: inline-block;
			width: 25%;
			vertical-align: top;
			position: relative;
			
			margin-bottom: 15px;
			
			@media (max-width: $screen-lg) {
				width: 50%;
			}
			@media (max-width: $screen-md) {

				width: 100%;
			}


			font-size: 15px;
			padding: 0 15px;
			
			.titre-diplome {
				color: $violet;
				font-size: 24px;
				text-transform: uppercase;
			}
			
			.desc-diplome {
				line-height: 26px;
				
			}
			
			.btn-container {
				position: absolute;
				bottom: 15px;
				left: 0;
				right: 0;
			}
			
			> .wrapper {
				padding: 15px 15px 60px 15px;
				background: #fff;
			}
		}
		
	}
	
	.title {
		p {
			color: #fff;
			text-align: center;
			font-size: 40px;
		}
	}

	.bloc-texte {
		@include bloc-texte-blanc;
		text-align: center;
	}
}