.page-template-template-type {

	.section-specificite {
		@include degrade-rose-jaune;
		
		h2,h3,h4 {
			color: $violet;
			font-size: 40px;
		}
				
		.bloc-texte {
			@include bloc-texte-blanc;
		}
	}
	

}