.single-index_metier, .page-template-template-diplomes {
	
	.description-metier {
		
		padding: 35px 0;
		background-position: right bottom;
		background-repeat: no-repeat;
		
		@media(max-width: $screen-sm) {
			background: none !important;
		}
		
		h1 {
			font-size: 40px;
			color: $violet;
		}
		
		h2 {
			font-size: 20px;
			text-transform: uppercase;
			color: $violet;
		}
		
		.fiche-recap {
			@include degrade-rose-jaune;
			padding: 15px;
			
			color: #fff;
			
			p, ul li {
				color: #fff;
			}
			
			.title {
				text-transform: uppercase;
				font-size: 18px;
				padding-left: 30px;
				position: relative;
				
				&:before {
					content: url('../images/puce-li.png');
					left: 15px;
					position: absolute;
					top: 0;
				}
			}
			
			ul {
				list-style: none;
				padding: 0;
				
				> li {
					margin-bottom: 5px;
				}
			}
		}
		
	}

	.diplomes-metier {
		padding: 35px 0;
		@include degrade-violet-rose;
		
		.title {
			font-size: 30px;
			color: #fff;
			text-align: center;
		}
		
		.description {
			color: #fff;
			text-align: center;
			margin-bottom: 35px;
		}
		
		ul.list-diplomes {
			text-align: center;
			> li {
				display: inline-block;
				width: 20%;
				padding: 0 15px;
				vertical-align: top;

				@media(max-width: $screen-md) {
					width: 100%;
					margin-bottom: 5px;
				}

				
				> .wrapper {
					background: #fff;
					padding: 15px;
					
					.title-diplome {
						color: $violet;
						text-transform: uppercase;
						font-size: 18px;
						text-align: center;
					}
					
					.description-diplome {
						line-height: 22px;
					}
					
					.lien {
						text-align: center;
					}
				}
			}
		}
	}

	.section-index-metier {
		background: $rose;
		padding: 70px 0;
		
		p {
			margin: 0;
		}
		
		.btn {
			border: 2px #fff solid;
		}
	}

}