header.banner {
	
	.container-menu-top {
		position: relative;
		
		.baseline {
			position: absolute;
			bottom: 45px;
			right: 30px;
			font-size: 50px;
			margin: 0;
			font-weight: 100;
			
			span {
				
				&:before {
					content: url('../images/before-title.png');
					position: absolute;
					left: -50px;
				}
			}
		}
		
		.top-menu {
			
			@media(max-width: $grid-float-breakpoint) {
				display: none;
			}
			
			position: absolute;
			right: 0;
			z-index: 6000;
			top: 15px;
			
			#menu-menu-top {
				margin: 0;
				padding: 0;
				
				& > li {
					display: inline-block;
					padding: 0 15px;
					
					& > a {
						color: $text-color !important;
						font-weight: 400 !important;

						&:hover {
							background-color: transparent !important;
						}

						&:focus {
							background-color: transparent !important;
						}

						&:active {
							background-color: transparent !important;
						}
					}

					& > ul {
						background-color: #FFF !important;
						border: none!important;

						& > li {
							& > a {
								color: $text-color !important;

								&:hover {
									color: $text-color !important;
								}
							}
						}
					}
				}
			}
		}
		
	}
		
	.top-line {
		padding: 8px 0;

		@include degrade-violet-rose;
		
		p, p a {
			color: #fff;
			font-size: 13px;
			margin: 0;
			
			@media(max-width: $grid-float-breakpoint) {
				text-align: center;
			}
		}
	}
	
	.social-barre-mobile {
		background: $orange;
		padding: 5px 15px;
		//border-bottom-right-radius: 10px;
		//border-bottom-left-radius: 10px;
		font-size: 16px;
				
		.textwidget {
			
			text-align: center;
			
			i.fa {
				font-size: 24px;
			}
		}
		
		@media(min-width: $screen-lg) {
			display: none;
		}
		
	}
	
	a.brand {
		@media(max-width: $screen-lg) {
			display: none;
		}
		
		img {
			max-width: 250px;
			padding: $navbar-padding-horizontal 0;
		}
	}
	
	
		.logo-mobile {
			@media(min-width: $screen-lg) {
				display: none;
			}
			
			img {
				max-width: 100px;
				margin: 15px;
			}
		}
	
	button.navbar-toggle {
		margin-top: 28px;
		
		  border: none;
		  background: transparent !important;
		
		  &:hover {
		    background: transparent !important;
		  }
		
		  .icon-bar {
		    width: 22px;
		    transition: all 0.2s;
		  }
		  .top-bar {
		    transform: rotate(45deg);
		    transform-origin: 10% 10%;
		  }
		  .middle-bar {
		    opacity: 0;
		  }
		  .bottom-bar {
		    transform: rotate(-45deg);
		    transform-origin: 10% 90%;
		  }

		  &.collapsed {
			    .top-bar {
    transform: rotate(0);
  }
  .middle-bar {
    opacity: 1;
  }
  .bottom-bar {
    transform: rotate(0);
  }
}		
	}
	
	.navbar-default, .navbar-default .navbar-collapse, .navbar-default .navbar-form {
		border: none;
	}
	
	nav {
		position: relative;

		.social-barre-desktop {
			position: absolute;
			right: 0;
			top:0;
			padding: 5px 0;
			font-size: 16px;
			
			.textwidget {
				text-align: right;
			}
			
			.title {
				font-size: 14px;
				line-height: 16px;
				font-style: italic;
				color: #999;
				font-weight: 300;
				margin: 0 0 10px 0;
			}

			@media(max-width: $screen-lg) {
				display: none;
			}
			
			i.fa {
				font-size: 30px;
				color: $vert;
			}
		}

		ul.nav {
			position: relative;
			padding-left: 55px;
			
			@media(min-width: $grid-float-breakpoint) {
				&:before {
					background: url('../images/corner-left-menu.png');
					background-size: cover;
					background-repeat: no-repeat;
					background-position: left top;
					display: block;
					width: 70px;
					left: 0px;
					height: 100%;
					position: absolute;
					top: 0;
					z-index: 1;
				}
			}
			
			@include degrade-rose-jaune;
						
			@media(min-width: $screen-lg) {
				position: absolute;
				right: 0;
				bottom: 0;
			}
			
			li.current-menu-ancestor, li.current-menu-parent {
				> a {
					color: #fff;
				}
			}
			
			> li {
				padding: 0 30px;
				
				&.mobileonly {
					@media(min-width: $grid-float-breakpoint) {
						display: none;
					}
				}
				
				> a {
					text-transform: uppercase;
					font-weight: 400;
					font-size: 16px;
					cursor: pointer;
				}
			}
		}
	}
}