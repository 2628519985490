.section-degrade-violet {
	@include degrade-violet-rose;
	
	padding-bottom: 35px;
	
	.bloc-index-metiers, .block-fb, .bloc-temoignages {
		
		@media(min-width: $screen-md) {
			border-left: 15px solid transparent;
			border-right: 15px solid transparent;
		}
		
		margin-bottom: 15px;
		
		> .vc_column-inner  {
			background: #fff;
			padding-top: 30px;
			padding-bottom: 30px;
		}
		
		.bouton p {
			position: absolute;
			bottom: 15px;
			left: 0;
			right: 0;
		}
		
		.titre-bloc {
			color: $violet;
			text-transform: uppercase;
			font-size: 20px;
		}
		
		.sous-titre-bloc {
			font-size: 20px;
		}
				
	}
	
	.bloc-index-metiers {
		h3 {
			color: $rose;
			text-transform: uppercase;
			font-size: 16px;
		}
	}

	.bloc-temoignages {
		background-image: url('../images/bg-temoignage.jpg');
		background-size: cover;
		background-position: center center;

		> .vc_column-inner  {
			background: transparent;
		}
		
		.texte {
			position: relative;
			
			p {
				padding: 0 30px;
			}
			
			&:before {
				content: "";
				font-family: "FontAwesome";
				color: $violet;
				top: 0;
				left: -15px;
				font-size: 40px;
				position: absolute;
			}

			&:after {
				content: "";
				font-family: "FontAwesome";
				color: $rose;
				bottom: 0;
				right: -15px;
				font-size: 20px;
				position: absolute;
			}

		}

	}
	
	.bloc-fb .wpb_wrapper {
		text-align: center;
	}
	
}

.section-blocs-formations {
	
	.bloc-formation-hover {
		position: relative;
		cursor: pointer;
		
		&:before {
			content: "";
			display: block;
			height: 100%;
			left: 0;
			right: 0;
			top: 0;
			position: absolute;
			background-position: center center;
			background-size: cover;

			filter: grayscale(100%) brightness(50%);
			-webkit-filter: grayscale(100%) brightness(50%);
			-moz-filter: grayscale(100%) brightness(50%);
			-ms-filter: grayscale(100%) brightness(50%);
			-o-filter: grayscale(100%) brightness(50%);
			filter: url('grayscale.svg#greyscale');
			filter: gray  brightness(50%);
			-webkit-filter: grayscale(1) brightness(50%);
	
			@include transition (all 0.3s);
			
		}


	
	
		&:hover {
			&:before {
				filter: inherit;
				-webkit-filter: inherit;
				-moz-filter: inherit;
				-ms-filter: inherit;
				-o-filter: inherit;
				filter: inherit;
				filter: inherit;
				-webkit-filter: inherit;
			}
		}	
	
		&.bloc-formation-cap:before {
			background-image: url('../images/bg-formation-cap.jpg');
			background-position: center center;
			background-size: cover;
		}
	
		&.bloc-formation-bts:before {
			background-image: url('../images/bg-formation-bts.jpg');
			background-position: center center;
			background-size: cover;
		}
	
		&.bloc-formation-bac:before {
			background-image: url('../images/bg-formation-bac.jpg');
			background-position: center center;
			background-size: cover;
		}
		
		> .vc_column-inner {
			padding-bottom: 60%;

		

			> .wpb_wrapper {
				position: absolute;
				top: 50%;
				@include transform(translateY(-50%));
				left: 15px;
				right: 15px;
				
				.titre-formation {
					
					margin-bottom: 10px;
					
					p,a {
						color: #fff;
						font-size: 40px;
						text-align: center;
						text-transform: uppercase;
						
					}
				}
				
				
				.descriptif-formation {
					margin-bottom: 0;
					
					p,a {
						color: #fff;
						text-align: center;
						text-transform: uppercase;
					}
				}
				
			}
		}
	}
	
}


.section-avantages {
	.texte-rose {
		color: $rose;
		font-size: 20px;
	}
}

.section-filiere {
	
	border-top: 1px solid #000;
	
	&.first-col {
		border: none 0;
	}
	
	.titre-violet {
		h2,h3 {
			color: $violet;
		}
	}
	
	.titre-rose {
		h2,h3 {
			color: $rose;
		}
	}
	
	.texte p {
		line-height: 30px;
	}
	
	@media(min-width: $screen-sm) {
		.desktop-first {
			order: -1;
		}
	}
}