footer.content-info {
	@include degrade-radial-gris;
	
	padding-top: 35px;
	
	section {
		
		.title {
			font-size: 14px;
			text-transform: uppercase;
			font-weight: 500;
		}
		
		&.widget_text {
			p, a {
				color: $text-color;
				font-size: 14px;
			}
		}
		
		&.widget_nav_menu {
			ul.menu {
				list-style: none;
				padding: 0;
				margin: 0;
				
				li {
					> a {
						color: $text-color;
						font-size: 14px;
						
						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}