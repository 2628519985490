#home-slider {		
	.carousel-inner {
		overflow: hidden;
		
		.mask-slider {
			
			@media(max-width: $screen-xs) {
				display: none;
			}
			
			position: absolute;
			top: 50%;
			right: 0;
			z-index: 1;
			@include transform(translateY(-50%));
			img {
				display: block;
				width: 500px;
				height: auto;
			}
			
		}
	}

	.label-nav {
		background: #676668;
		font-size: 34px;
		padding: 15px 0;
		text-align: center;
		color: #fff;
		font-weight: 700;
	}

	.carousel-indicators {
	  position: static;
	  bottom: 0 none;
	  left: auto;
	  z-index: 15;
	  width: 100%;
	  margin-left: 0;
	  padding-left: 0;
	  list-style: none;
	  text-align: left;
	  margin-bottom: 0;
	
	  li {
		float: left;
	    display: block;
	    width:  25%;
	    height: 80px;
	    margin: 0;
	    text-indent: inherit;
	    border: 0 none;
	    border-radius: 0;
	    cursor: pointer;
		
		padding-left: 40px;
		position: relative;
		/*  transition: 0.5s all;
		  	-ms-transition: 0.5s all;
		  	-moz-transition: 0.5s all;
		  	-webkit-transition: 0.5s all;
		  	-o-transition: 0.5s all;*/
		  	
		
		transition: background 0.6s ease-out;

		&:nth-child(1) {
			background: $violet;
		}
		
		&:nth-child(2) {
			background: $rose;
		}

		&:nth-child(3) {
			background: $jaune;
		}

		&:nth-child(4) {
			background: $orange;
		}
		
		
		@media(max-width: $screen-lg) {
			float: left;
			width: 50%;
		}

		@media(max-width: $screen-sm) {
			width: 100%;
			float: left;
		}


		&:last-child {
			@media(min-width:$screen-lg) {
				//border: 0 none;
			}
			border-right: 0 none;
		}
		
		p {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			color: #fff;
			left: 0;
			right: 0;
			text-align: center;
			text-transform: uppercase;
			font-weight: 300;
			font-size: 18px;
						
			span {
				color: $orange;
				font-weight: 300;
			}
		}
	  }
	  .active {
	    margin: 0;
	    width:  25%;
	    height: 80px;
	    //background-color: #8c8a8a;
		
		@media(max-width: $screen-lg) {
			width: 50%;
			float: left;
		}

		@media(max-width: $screen-sm) {
			width: 100%;
			float: left;
		}
			
			
			
			&:first-child {
				background-image: url('../images/active-cursor.png');
				background-repeat: no-repeat;
				background-position: center top;
			}
			
			&:nth-child(2) {
				background-image: url('../images/active-cursor.png');
				background-repeat: no-repeat;
				background-position: center top;
			}
	
			&:nth-child(3) {
				background-image: url('../images/active-cursor.png');
				background-repeat: no-repeat;
				background-position: center top;
			}
	
			&:nth-child(4) {
				background-image: url('../images/active-cursor.png');
				background-repeat: no-repeat;
				background-position: center top;
			}

	  }
	}	
		.item {
		//position: relative;
		background-size: cover;
		background-position: center center;
		
		.container-texte {
			position: relative;
			
			
			.wrapper-slide {
							
				position: absolute;
				right:0;
				left: 0;
				//bottom: 40px;
				top: 0;
				height: 450px;
				}
				
				.container-cadre {
				
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 0;
				//left: 0;
				width: 300px;
				padding-left: 5%;
				
				@media(min-width: $screen-sm) {
					right: 60px;
				}
				
				z-index: 10;

					a {
						text-decoration: none;
						
						&:hover {
							text-decoration: none;
						}
					}
					
				@media(max-width: $screen-xs) {
					left: 15px;
					right: 15px;
				}

				p.titre-slide {
					margin-bottom: 10px;
					

					span {
						color: #fff;
						padding: 5px;
						font-size: 32px;
						font-weight: 300;
						text-transform: uppercase;
						text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
					}
				}
				
				p.texte-slide {
					
					@media(max-width: $screen-md) {
						font-size: 40px;
						line-height: 40px;
						
						.thinner {
							font-size: 30px;
							line-height: 30px;
							padding-left: 40px;
						}
					}
					
					@media(min-width: $screen-md) and (max-width: $screen-lg) {
						font-size: 40px;
						line-height: 40px;
						
						.thinner {
							font-size: 30px;
							line-height: 30px;
							padding-left: 40px;
						}
					}
					
					@media(min-width: $screen-lg) {
						line-height: 37px;
						font-size: 27px;
						
						.thinner {
							font-size: 30px;
							line-height: 30px;
							padding-left: 40px;
						}
					}
					
					//padding-left: 60px;
					text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
					color: #fff;
					
					a {
						color: #fff;
					}
				}
			}
			
		}
	}
}


