@mixin degrade-violet-rose () {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#662f8e+0,ea2b7b+100 */
	background: #662f8e; /* Old browsers */
	background: -moz-linear-gradient(left, #662f8e 0%, #ea2b7b 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left, #662f8e 0%,#ea2b7b 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, #662f8e 0%,#ea2b7b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#662f8e', endColorstr='#ea2b7b',GradientType=1 ); /* IE6-9 */
}

@mixin degrade-rose-jaune () {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ea2b7b+0,f9b963+100 */
	background: #ea2b7b; /* Old browsers */
	background: -moz-linear-gradient(left, #ea2b7b 0%, #f9b963 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left, #ea2b7b 0%,#f9b963 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, #ea2b7b 0%,#f9b963 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ea2b7b', endColorstr='#f9b963',GradientType=1 ); /* IE6-9 */
}

@mixin degrade-radial-gris () {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,e6e6e6+100 */
	background: #ffffff; /* Old browsers */
	background: -moz-radial-gradient(center, ellipse cover, #ffffff 0%, #e6e6e6 100%); /* FF3.6-15 */
	background: -webkit-radial-gradient(center, ellipse cover, #ffffff 0%,#e6e6e6 100%); /* Chrome10-25,Safari5.1-6 */
	background: radial-gradient(ellipse at center, #ffffff 0%,#e6e6e6 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e6e6e6',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.h2 {
	font-size: 20px;
	text-transform: uppercase;
	color: $violet;
}

.texte-violet h1, .texte-violet h2, .texte-violet h3,  {
	color: $violet;
}

.texte-jaune h1, .texte-jaune h2, .texte-jaune h3,  {
	color: $jaune;
}

.texte-rose h1, .texte-rose h2, .texte-rose h3,  {
	color: $rose;
}

.texte-orange h1, .texte-orange h2, .texte-orange h3,  {
	color: $orange;
}

@mixin shadow-box-rose {
	-webkit-box-shadow: 0px 0px 5px 0px $rose;
	-moz-box-shadow: 0px 0px 5px 0px $rose;
	box-shadow: 0px 0px 5px 0px $rose;
}

@mixin bloc-texte-blanc {
	p {
		line-height: 30px;
		color: #fff;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		
		li {
			color: #fff;
			margin-bottom: 10px;
			//position: relative;
			&:before {
				content: url('../images/puce-li.png');
				margin-right: 5px;
			}
		}
	}
}

p, ul li, ol li {
	font-weight: 300;
}

.bloc-texte {
	p {
		line-height: 30px;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		
		li {
			margin-bottom: 10px;
			//position: relative;
			&:before {
				content: url('../images/puce-li.png');
				margin-right: 5px;
			}
		}
	}
}