.btn.btn-gris {
	background: rgba(88,89,91,0.4);
	color: #fff;
}

.btn.btn-degrade {
	@include degrade-rose-jaune;
	color: #fff;
	background-repeat: no-repeat;
	
	&:hover {
		opacity: 0.8;
	}
}

.btn.btn-violet {
	@include degrade-violet-rose;
	color: #fff;
	
	&:hover {
		opacity: 0.8;
	}
}

.btn.btn-blanc {
	background: #fff;
	color: $text-color;
}

.btn-file {
	background-color: white !important;
	border: 1px solid $rouge;
	color: $rouge;
	transition: .5s;
	height: 100%;

	&:hover {
		background-color: $rouge !important;
		color: white !important;
	}

	& > span {
		& > span {
			position: absolute;
			font-size: 1.2rem;
			top: 40px;
			text-transform: none;
		}
	}
}

.file-input {
	display: none !important;
}