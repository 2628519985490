.metier-template-default {
	
	.description-metier {
		
		padding: 35px 0;
		background-position: right bottom;
		background-repeat: no-repeat;
		
		@media(max-width: $screen-sm) {
			background: none !important;
		}
		
		h1 {
			font-size: 40px;
		}
		
		h2 {
			font-size: 20px;
			text-transform: uppercase;
		}
		
		.fiche-recap {
			@include degrade-rose-jaune;
			padding: 15px;
			
			color: #fff;
			
			p, ul li {
				color: #fff;
			}
			
			.title {
				text-transform: uppercase;
			}
			
			ul {
				list-style: none;
				padding: 0;
				
				> li {
					margin-bottom: 5px;
				}
			}
		}
		
		&.automobile {
			
			h1,h2 {
				color: $violet;
			}
			
		}
	}

	.temoignages {
		
		
		position: relative;
		padding: 35px 0;
		
		.temoignage {
			&:before {
				content: url('../images/temoignage-left.png');
				position: absolute;
				left: -250px;
				top: -100px;
			}
			&:after {
				content: url('../images/temoignage-right.png');
				position: absolute;
				right: -250px;
				top: -50px;
			}
		}
		
		&.automobile {
			background: $violet;
		}
		
		.title {
			font-size: 30px;
			color: #fff;
			text-align: center;
		}
		
		.temoignage, .temoignage p {
			color: #fff;
			text-align: center;
		}
	}
	
	.le-diplome {
		@include degrade-violet-rose;
		padding: 35px 0;

		.title {
			font-size: 30px;
			color: #fff;
			text-align: center;
		}
		
		h2,h3 {
			color: #fff;
		}
		
		p {
			color: #fff;
			text-align: center;
		}
		
	}	

}

.tous-les-metiers {
	padding: 35px 0;
	
	.title {
		color: $jaune;
		text-align: center;
		font-size: 30px;
	}
	
	.description {
		text-align: center;
		margin-bottom: 15px;
	}
	
	.box {
		
		margin-bottom: 15px;
		
		.box-title {
			text-align: center;
			color: #fff;
			text-transform: uppercase;
			font-size: 18px;
		}
		p {
			text-align: center;
			color: #fff;
		}
		
		> .wrapper {
			padding: 15px;
		}
		
		&.box-automobile {
			>.wrapper {
				background: $violet;
			}
			
		}
		&.box-commerce {
			>.wrapper {
				background: $rose;
			}
			
		}
		&.box-fleuriste {
			> .wrapper {
				background: $jaune;
			}
		}
		&.box-nucleaire {
			> .wrapper {
				background: $orange;
			}
		}
	}
}